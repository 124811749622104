/* https://github.com/vitalikda/form-floating-labels-tailwindcss */

input:focus ~ label,
input:not(:placeholder-shown) ~ label,
textarea:focus ~ label,
textarea:not(:placeholder-shown) ~ label,
select:focus ~ label,
select:not([value=""]):valid ~ label {
  @apply scale-75;
  @apply -translate-y-6;
}

input:focus ~ label,
select:focus ~ label {
  @apply text-black;
  @apply left-0;
}