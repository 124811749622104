.ProseMirror table {
	border-collapse: collapse;
	margin: 0;
	overflow: hidden;
	table-layout: fixed;
	width: 100%;
}

.ProseMirror table td, th {
	border: 2px solid #ced4da;
	box-sizing: border-box;
	min-width: 1em;
	padding: 3px 5px;
	position: relative;
	vertical-align: top;
}

.ProseMirror table td, th > * {
	margin-bottom: 0;
}

.ProseMirror table th {
	background-color: #f1f3f5;
	font-weight: bold;
	text-align: left;
}
	
.ProseMirror table .selectedCell:after {
	z-index: 2;
	position: absolute;
	content: "";
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background: rgba(200, 200, 255, 0.4);
	pointer-events: none;
}
	
.ProseMirror table .column-resize-handle {
	background-color: #adf;
	bottom: -2px;
	position: absolute;
	right: -2px;
	pointer-events: none;
	top: 0;
	width: 4px;
}
	
.ProseMirror table p {
	margin: 0;
}

.ProseMirror table p.is-editor-empty:first-child::before {
	content: attr(data-placeholder);
	float: left;
	color: #353535;
	pointer-events: none;
	height: 0;
}

.ProseMirror table .resize-cursor {
	cursor: ew-resize;
	cursor: col-resize;
}


.ProseMirror ul, ol {
	list-style: initial;
	padding-left: 20px;
}

.ProseMirror blockquote {
	@apply ml-4 pl-4 border-l-4 border-gray-200
}

.ProseMirror h1 {
	@apply text-5xl;
}

.ProseMirror h2 {
	@apply text-4xl;
}
	
.ProseMirror h3 {
	@apply text-3xl;
}

.ProseMirror .remix {
	width: 24px;
	height: 24px;
	fill: #333;
}