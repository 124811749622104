@import './components/inputs/inputs.css';
@import './components/editor/EditorInput.css';
@import './components/editor/tiptap.css';
@tailwind base;
@tailwind components;
@tailwind utilities;

.no-scrollbar::-webkit-scrollbar {
	scrollbar-width: 0px;
	width: 0px;
	display: none;
}

.no-scrollbar {
	scrollbar-width: none;
}

.narrow-scrollbar::-webkit-scrollbar {
  width: 3px;
}

.narrow-scrollbar::-webkit-scrollbar-thumb {
  background: #000;
}

.narrow-scrollbar {
	/*
		firefox implemented crap
		https://bugzilla.mozilla.org/show_bug.cgi?id=1432935#c15
	*/
	scrollbar-width: thin;
}

* {
	/*
	It's a nice idea to maintain scroll, if elements
	are removed from invisible area above the screen.
	But it isn't supported on safari atm
	*/
	overflow-anchor: none;
	/*
	Very buggy behavior of font boost. For example,
	it shows the 5th chat message larfer than previous
	four. Then it breaks the animation. The definition
	text-size-adjust: none; doesn't work. See also
	https://bugs.webkit.org/show_bug.cgi?id=84186#c27
	*/
	max-height: 100000px;
}

